// text

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
    letter-spacing: -0.02rem;
}

.text-inherit {
    color: $gray-900;
}


.text-primary-hover{
    &:hover{
        color: $primary !important;
    }
}
