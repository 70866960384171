.skeleton-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
}

.skeleton-header {
  display: flex;
  padding: 1rem;
  background-color: #e9ecef;
  border-bottom: 1px solid #dee2e6;
}

.header-item {
  flex: 1;
  font-weight: bold;
  color: #6c757d;
}

.skeleton-row {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
}

.skeleton-name-email {
  display: flex;
  align-items: center;
  flex: 2;
}

.skeleton-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin-right: 1rem;
}

.skeleton-details {
  flex: 1;
}

.skeleton-name, .skeleton-email {
  height: 0.8rem;
  background-color: #e0e0e0;
  margin-bottom: 0.5rem;
}

.skeleton-name {
  width: 60%;
}

.skeleton-email {
  width: 80%;
}

.skeleton-role, .skeleton-department, .skeleton-phone, .skeleton-status {
  flex: 1;
  height: 0.8rem;
  background-color: #e0e0e0;
}

@keyframes shimmer {
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
}

.skeleton-row > div > div {
  background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
}

.skeleton-status {
  width: 40px;
  border-radius: 12px;
}

@media (max-width: 768px) {
  .skeleton-row, .skeleton-header {
    flex-wrap: wrap;
  }
  
  .skeleton-name-email, .skeleton-role, .skeleton-department, .skeleton-phone, .skeleton-status {
    flex-basis: 100%;
    margin-bottom: 0.5rem;
  }
}