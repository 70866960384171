.live-user-tracker{
    .live-user-counter{
        display: inline-block;
        .show-website-dropdown{
            position: absolute;
            background: white;
            box-shadow: 0px 3px 22px;
            margin-top: 13px;
            z-index: 1;
            .web-title{
                font-size: 15px;
                padding: 2px 11px;
                margin-bottom: 9px;
                cursor: pointer;
            }
            .web-title:hover{
                background: #0a376a;
                color: white;
            }
        }
    }
}