.login-page {
//   background-color: $bg-color;
  .heading-section {
    font-size: 28px;
    color: #000;
  }
  .img {
    position: relative;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure the image doesn't overflow */
  }
  .img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../images/bg-1.jpg);
    background-size: cover;
    background-position: center;
    filter: blur(1px);
    z-index: 0;
  }
  .logo {
    width: 70%;
    position: relative; /* Ensure it stays on top of the blurred background */
    z-index: 1;
  }
  .wrap {
    max-height: 588px;
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  }
  .login-wrap h3 {
    font-weight: 300;
  }
  .form-group {
    position: relative;
    z-index: 0;
    margin-bottom: 20px !important;
  }
  .form-control {
    height: 48px;
    background: #fff;
    color: #000;
    font-size: 16px;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
  }
  .form-control-placeholder {
    position: absolute;
    top: 2px;
    padding: 7px 0 0 15px;
    -webkit-transition: all 400ms;
    -o-transition: all 400ms;
    transition: all 400ms;
    opacity: 0.6;
  }


  .form-control:focus,
  .form-control:active {
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #01d28e;
  }
  .checkbox-primary {
    color: #01d28e;
  }
  .checkbox-wrap {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkbox-wrap input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input[type="radio"],
  input[type="checkbox"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    overflow: visible;
  }
  .form-group a {
    color: gray;
  }

  .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: $primary-color;
    border-color:$primary-color; 
}
}


.google-register-area{
    margin-top: 0px!important;
    background-color: #fff;
}
.google-register-area > div:first-child {
  margin-top: 0px !important;
}

#googleSignInButton[role="button"] {
  border-radius: 33px!important;
  margin-top: 11px!important;
  height: 58px!important;
  padding-left: 105px!important;
  padding-right: 82px!important;
}