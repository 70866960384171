$white: #fff !default;
$gray-100: #f1f5f9 !default;
$gray-200: #f4f6f8 !default;
$gray-300: #dfe3e8 !default;
$gray-400: #c4cdd5 !default;
$gray-500: #919eab !default;
$gray-600: #637381 !default;
$gray-700: #454f5b !default;
$gray-800: #212b36 !default;
$gray-900: #161c24 !default;
$black: #000 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #f59e0b !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0ea5e9 !default;
$primary: #624bff !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$primary-color:#00234a;

// Light color
$light-primary: #e0dcfe !default; // NEW
$light-secondary: #e9ecef !default; // NEW
$light-success: #eaf6ec !default; // NEW
$light-danger: #fbebec !default; // NEW
$light-warning: #fff3cd !default; // NEW
$light-info: #e7fafe !default; // NEW
$light-dark: #d1cfd8 !default; // NEW

// Dark color
$dark-primary: #593cc1 !default; // NEW
$dark-secondary: #5c596d !default; // NEW
$dark-success: #139a74 !default; // NEW
$dark-danger: #ae302e !default; // NEW
$dark-warning: #c28135 !default; // NEW
$dark-info: #51a0c2 !default; // NEW

$theme-colors: () !default;
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "white": $white,
    "light-primary": $light-primary,
    "light-danger": $light-danger,
    "light-info": $light-info,
    "light-warning": $light-warning,
    "light-secondary": $light-secondary,
    "light-success": $light-success,
    "light-dark": $light-dark,
    "dark-primary": $dark-primary,
    "dark-warning": $dark-warning,
    "dark-info": $dark-info,
    "dark-danger": $dark-danger,
    "dark-success": $dark-success,
    "dark-secondary": $dark-secondary,
);

// Body
//
// Settings for the `<body>` element.

$body-color: $gray-600 !default;
// Typography
// Font, line-height, and color for body text, headings, and more.
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Inter", "sans-serif" !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-heading: $font-family-sans-serif;
// stylelint-enable value-keyword-case
$font-size-base: 0.9375rem !default; // Assumes the browser default, typically `15px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;
$link-hover-decoration: none !default;

$h1-font-size: 2.25rem !default;
$h2-font-size: 1.875rem !default;
$h3-font-size: 1.5rem !default;
$h4-font-size: 1.125rem !default;
$h5-font-size: 0.938rem !default;
$h6-font-size: 0.75rem !default;

$headings-color: $gray-800 !default;

// Line height
$line-height-base: 1.6 !default;
$line-height-1: 1 !default;
$line-height-2: 2 !default;
$line-height-sm: 1.2 !default;
$line-height-md: 1.6 !default;
$line-height-lg: 1.8 !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        // 4px
        2:
            (
                $spacer * 0.5,
            ),
        // 8px
        3:
            (
                $spacer * 1,
            ),
        // 16px
        4:
            (
                $spacer * 1.25,
            ),
        // 20px
        5:
            (
                $spacer * 1.5,
            ),
        // 24px
        6:
            (
                $spacer * 2,
            ),
        // 32px
        7:
            (
                $spacer * 2.5,
            ),
        // 40px
        8:
            (
                $spacer * 3,
            ),
        // 48px
        9:
            (
                $spacer * 3.5,
            ),
        // 56px
        10:
            (
                $spacer * 4,
            ),
        // 64px
        11:
            (
                $spacer * 4.5,
            ),
        // 72px
        12:
            (
                $spacer * 5,
            ),
        // 80px
        13:
            (
                $spacer * 5.5,
            ),
        // 88px
        14:
            (
                $spacer * 6,
            ),
        // 96px
        15:
            (
                $spacer * 6.5,
            ),
        // 104px
        16:
            (
                $spacer * 7,
            ),
        // 112px
        17:
            (
                $spacer * 7.5,
            ),
        // 120px
        18:
            (
                $spacer * 8,
            ),
        // 128px
        19:
            (
                $spacer * 8.5,
            ),
        // 136px
        20:
            (
                $spacer * 9,
            ),
        // 144px
        21:
            (
                $spacer * 9.5,
            ),
        // 152px
        22:
            (
                $spacer * 10,
            ),
        // 160px
        23:
            (
                $spacer * 12,
            ),
        // 288px
    ),
    $spacers
);
$border-radius: 0.375rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-sm: 0.2rem !default;

$smooth-shadow-sm: 0px 2px 4px rgba(0, 0, 20, 0.08), 0px 1px 2px rgba(0, 0, 20, 0.08) !default;

$smooth-shadow-md: 0 1px 2px rgba(21, 30, 40, 0.07), 0 2px 4px rgba(21, 30, 40, 0.07), 0 4px 8px rgba(21, 30, 40, 0.07),
    0 8px 16px rgba(21, 30, 40, 0.07), 0 16px 32px rgba(21, 30, 40, 0.07), 0 32px 64px rgba(21, 30, 40, 0.07) !default;

$smooth-shadow-lg: 0 1px 1px rgba(21, 30, 40, 0.11), 0 2px 2px rgba(21, 30, 40, 0.11), 0 4px 4px rgba(21, 30, 40, 0.11),
    0 8px 8px rgba(21, 30, 40, 0.11), 0 16px 16px rgba(21, 30, 40, 0.11), 0 32px 32px rgba(21, 30, 40, 0.11) !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 1rem !default;
$input-btn-font-family: null !default;
$btn-font-weight: $font-weight-medium !default;

$form-label-color: $gray-800 !default;

// Avatar
$avatar-size-xs: 1.5rem !default;
$avatar-size-sm: 2rem !default;
$avatar-size-md: 2.5rem !default;
$avatar-size-lg: 3.5rem !default;
$avatar-size-xl: 5rem !default;
$avatar-size-xxl: 7.5rem !default;

// Icon
$icon-size-xxs: 0.875rem !default;
$icon-size-xs: 1rem !default;
$icon-size-sm: 1.5rem !default;
$icon-size-md: 2.5rem !default;
$icon-size-lg: 3rem !default;
$icon-size-xl: 3.5rem !default;
$icon-size-xxl: 4rem !default;
$enable-responsive-font-sizes: false !default;

// Position
// Define the edge positioning anchors of the position utilities.
$position-values: (
    0: 0,
    1: 1,
    2: 2,
    50: 50%,
    100: 100%,
) !default;

// Negative margins
$enable-negative-margins: true !default;

// Link Decoration

$link-decoration: none !default;

// grid gutter space
$grid-gutter-width: 1.5rem !default;

// Cards

$card-spacer-y: 1.25rem !default;
$card-spacer-x: 1.25rem !default;
$card-inner-border-radius: 0.5rem !default;
$card-title-spacer-y: $spacer * 1.5 !default;

// Letter Spacing

$letter-spacing-sm: -0.04em;
$letter-spacing-xs: -0.08em;
$letter-spacing-base: 0em;
$letter-spacing-md: 0.1em;
$letter-spacing-lg: 0.15em;
$letter-spacing-xl: 0.2em;
$letter-spacing-xxl: 0.25em;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-head-color: $gray-700 !default;

$table-cell-padding-y: 0.75rem !default;
$table-cell-padding-x: 1.5rem !default;
$table-cell-padding-y-sm: 0.3rem !default;
$table-cell-padding-x-sm: 0.6rem !default;

$table-hover-bg: rgba($gray-100, 0.7) !default;

// table striped

$table-striped-bg-factor: 1 !default;
$table-striped-bg: rgba($gray-100, $table-striped-bg-factor) !default;

// table active

$table-active-bg-factor: 1 !default;
$table-active-bg: rgba($gray-100, $table-active-bg-factor) !default;

$table-group-separator-color: $gray-200 !default;
