//
// Extended from bootstrap
//
// card


.card{
    box-shadow: $smooth-shadow-sm;
    border: 0px;
    border-radius: $border-radius-lg;
}