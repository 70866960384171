.generate-lead-form {
  .input-file-drop {
    align-content: center;
    height: 80px;
    border: 1px dashed;
    border-radius: 12px;
    margin-top: 9px;
    cursor: pointer;
  }
  .parent-upload {
    margin: 0 auto;
    width: 213px;
    margin: 0 auto;
    span {
      text-align: center;
      display: inline-block;
      width: 100%;
      svg {
        margin: 0 auto;
      }
    }
  }
}

.custom-select-search {
  position: relative;
  margin-top: -39px;
}

.chip-row {
  margin-bottom: 0px;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 1s ease-in, height 0.3s ease-in-out;
  .chip-child {
    display: inline-block;
    margin: 5px 10px;

    .MuiButtonBase-root {
      color: #0a376a;
      border: 1px solid #0a376a;
    }
    .MuiButtonBase-root:hover {
      background-color: #0e2743;
      color: white;
    }
  }
  .active {
    .MuiButtonBase-root {
      color: white;
      background-color: #0a376a;
      border: 1px solid #0a376a;
    }
  }
}
.chip-row.show {
  opacity: 1;
  height: auto;
}

.filter-icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 11px;
  cursor: pointer;
}

.applied-filter-icon {
  svg {
    fill: #0a376a;

    circle,
    line,
    path {
      fill: #0a376a;
    }

    &:hover {
      fill: #0a376a;

      circle,
      line,
      path {
        fill: #0a376a;
      }
    }
  }
}

.image-list-custom {
  margin: 10px 0px;
  padding: 0px;
  li {
    display: inline-block;
    margin: 1px;
  }
}
.edit-icon-lead {
  display: inline-block;
  line-height: 10px;
  vertical-align: bottom;
}

// scroller
/* Global scrollbars for WebKit browsers */
* {
  scrollbar-width: none; /* For Firefox */
  scrollbar-color: $primary-color #fff; /* For Firefox */
}

/* Global scrollbars for WebKit browsers */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background-color: primary-color;
}

*::-webkit-scrollbar-thumb {
  background-color: primary-color;
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: primary-color;
}

.action-td {
  padding-left: 9px!important;
  padding-right: 0px!important;
  margin-right: 0px;
  width: 10px;
  cursor: pointer;
}

.action-th{
  width: 3px;
  padding: 0px!important;
  cursor: pointer;
}
.show-table-action{
  height: 118px;
  width: 130px;
  background-color: red;
  position: absolute;
  margin-left: 25px;
}


.tr-danger{
 td{
  background-color: red;
 }
}

button:disabled{
  color: white!important;
  background: #5c7692 !important;
}

.show-message-query{
  p,span,strong{
    color: black!important;
  }
}