.open-chat-area {
  max-width: 350px;
  max-height: 76vh;

  .subject-selection {
    // height: 100vh;
    width: 250px;
    // height: 76vh;
    position: fixed;
    bottom: 64px;
    z-index: 9999999999999;
    left: 20px;
    margin-left: -5px;

    .first-heading {
      padding: 16px;
      margin: 0 5px;
      cursor: pointer;
      box-shadow: inset 0 0 0 1px #e1e6f9, 0px 2px 4px rgba(0, 17, 119, 0.02),
        0px 4px 15px rgba(0, 17, 119, 0.02);
      border-radius: 9px;
      background-color: #ffffff;
      border-color: #e1e6f9;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      color: #1e2235;
    }
    ul {
      padding-left: 0;
      li {
        border-color: #e1e6f9 !important;
        color: #1e2235 !important;
        cursor: pointer;
        display: inline-block;
        position: relative;
        padding: 6px 15px 8px 32px !important;
        box-shadow: inset 0 0 0 1px #e1e6f9, 0px 2px 4px rgba(0, 17, 119, 0.02),
          0px 4px 15px rgba(0, 17, 119, 0.02);
        margin: 5px;
        margin-bottom: 0;
        border-radius: 1.3em;
        transition: width 2s;
        word-break: break-word;
        line-height: 20px;
        background-color: #ffffff;
        letter-spacing: 0.02em;
        transition: background 0.2s linear, transform 0.2s,
          box-shadow 0.2s linear;
        font-size: 16px;
      }
      li::before {
        content: "";
        background-color: #e1e1e1;
        width: 12px;
        height: 12px;
        position: absolute;
        left: 12px;
        top: 14px;
        border-radius: 50%;
        transition: background 0.2s linear;
      }
      li:hover::before {
        background-color: #8c52ff !important;
      }
      li:hover {
        box-shadow: inset 0 0 0 1px #8c52ff, 0px 2px 4px rgba(0, 17, 119, 0.02),
          0px 4px 15px rgba(0, 17, 119, 0.02);
      }
    }
  }
  .chat-box {
    min-width: 354px;
    max-height: 86vh;
    background: white;
    position: absolute;
    bottom: 47px;
    left: 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 8px;
    box-shadow: -2px 11px 16px;
    transition: transform 0.2s ease-in-out;
    transition-property: transform, opacity;
    opacity: 1;
  }

  .main-agent-avatar-open {
    cursor: pointer;
    width: 60px;
    height: 60px;
    bottom: 10px;
    background-size: 60px;
    border-radius: 50% !important;
    margin: -1px;
    // box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    position: fixed;
    left: 22px;
    background-image: url(https://avatars.collectcdn.com/660bb61b0f95c58c78ec343e/660bb61b0f95c58c78ec343e-665d63771063215eaa123088.jpeg?t=1717396828220);
  }
  // .main-agent-avatar-open::after {
  //   content: "";
  //   position: absolute;
  //   width: 26px;
  //   height: 26px;
  //   border-radius: 50%;
  //   background-color: #2ecc71;
  //   margin-left: 47px;
  //   margin-top: 34px;
  // }

  .notification-badge {
    position: absolute;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: #2ecc71;
    margin-left: 47px;
    margin-top: 38px;
    text-align: center;
    align-content: center;
    font-weight: 700;
    color: black;
  }
  .main-agent-avatar-open:hover {
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.25);
  }
}

.open-chat-area {
  .chat-box {
    margin: 0px auto 40px auto;
    padding: 20px 15px;
    width: 362px;
    padding-bottom: 46px;
    padding-top: 6px;
    min-height: 308px;
    .chat-header {
      height: 48px;
      width: 310px;
      background: white;
      position: fixed;
      z-index: 1;
      margin-top: -6px;
    }
    .conversation-part--question {
      max-width: 100%;
      margin-bottom: 8px;
    }
    .avatar-wrapper {
      height: 28px;
      width: 28px;
      max-width: 10%;
      margin-right: 10px;
      display: inline-block;
      vertical-align: top;
      .avatar {
        background-color: #8c52ff;
        background-image: url(https://avatars.collectcdn.com/660bb61b0f95c58c78ec343e/660bb61b0f95c58c78ec343e-665d63771063215eaa123088.jpeg?t=1717396828220);
        background-size: 29px;
        height: 28px;
        width: 28px;
        background-repeat: no-repeat;
        background-position: 50%;
        border-radius: 50%;
      }
    }
    .same-row {
      display: inline-block;
      vertical-align: top;
    }
    .question-part {
      max-width: 86%;
      transition: width 2s;
      overflow: hidden;
      border-radius: 1.3em;
      border: 1px solid transparent;
      padding: 10px 17px;
      transition: width 2s;
      word-break: break-word;
      line-height: 20px;
      background-color: #f8f8f8;
      text-align: initial;
      color: #455a64;
      font-size: 15.5px;
      font-family: "Open Sans", sans-serif;
      -webkit-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      font-weight: 400px;
    }
    .its-me {
      float: right;
      .question-part {
        background-color: #8c52ff !important;
        color: white;
      }
      .clear-both {
        clear: both;
      }
    }
    .comment {
      color: #b0bec5;
      font-size: 12px;
      display: block;
      padding-left: 38px;
      padding-top: 4px;
      padding-bottom: 8px;
    }
    .comment-right {
      text-align: end;
      padding-right: 17px;
      padding-top: 4px;
      padding-bottom: 2px;
    }
  }
  .bottom-input {
    width: 378px;
    height: 63px;
    background: #fff;
    position: fixed;
    bottom: 88px;
    left: 20px;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    .send-icon {
      position: absolute;
      top: 21px;
      right: 49px;
    }

    .number-input-group {
      width: 80%;
      margin-left: 26px;
    }
    .country-code-dropdown {
      .drop-down-list {
        bottom: 67px;
      }
      .selected-value {
        font-size: 16px;
        padding-top: 11px;
      }
    }
    .number-input {
      padding: 20px 3px;
      padding-right: 64px;
      font-size: 16px;
      outline-width: 0px;
      border: 0px;
      box-shadow: none;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .number-input:focus {
      outline-width: 0px;
      border: 0px;
      box-shadow: none;
    }
  }
  .chat-action-btn {
    position: absolute;
    right: 14px;
    top: 21px;
    cursor: pointer;
  }
  .chat-file-btn {
    position: absolute;
    display: inline-block;
    margin-top: -41px;
    right: 82px;
  }
  .show-typing-effect {
    height: 44px;
    width: 126px;
    display: inline-block;
    img {
      width: 111px;
      height: 57px;
      margin-left: -25px;
      margin-top: -10px;
    }
  }
  .skip-message-btn{
    position: absolute;
    color: white;
    padding: 6px 20px;
    border-radius: 22px;
    margin-top: -33px;
    right: 22px;
    border: 1px solid #8c52ff;
    color: #8c52ff;
    cursor: pointer;
  }
}

@media only screen and (max-width: 371px) {
  .open-chat-area {
    .chat-box {
      min-width: 331px;
      height: 100vh;
      width: 336px;
    }
    .bottom-input {
      width: 334px;
    }
  }
}

@media only screen and (max-width: 328px) {
  .open-chat-area {
    .chat-box {
      min-width: 331px;
      height: 100vh;
      width: 326px;
    }
    .bottom-input {
      width: 362px;
    }
  }
}

// admin side
.admin-side-support-chat {
  max-width: 350px;
  max-height: 76vh;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 1;
}

@media only screen and (width: 375px) {
  .open-chat-area {
    .chat-box {
      .chat-header {
        width: 333px;
      }
    }
  }
}

.admin-side-support-chat {
  .open-chat-area {
    .chat-box {
      width: 379px;
      .chat-header {
        width: 340px;
      }
    }
  }
}


// .open-chat-area{
//     .custom-number-error{
//      border: 1px solid red;
//     }
// }