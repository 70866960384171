.delivery-box-input-file {
  height: 52px;
  border: 1px dashed #0a376a;
  margin-bottom: 7px;
  border-radius: 10px;
  align-content: center;
  cursor: pointer;
}

.delivery-chat-box {
  text-align: left;
  .list-p {
    font-size: 13px;
  }
  .correct {
    color: greenyellow;
    font-weight: bold;
    font-size: 15px;
  }
  .wrong {
    color: rgb(255, 31, 31);
    font-weight: bold;
    font-size: 15px;
  }
  .file-list {
    i {
      font-size: 30px;
      margin: 2px 5px;
      color: white;
    }
  }
}

.download-all-btn {
  cursor: pointer;
  a{
    color: white;
  }
  i {
    font-size: 20px;
    padding-bottom: 8px;
  }
}
