.custom-chat-area {
  width: 401px;
  border: 1px solid;
  position: fixed;
  right: 0px;
  // min-height: 300px;
  margin-right: 10px;
  z-index: 10000;
  margin-bottom: 10px;
  background: white;
  box-shadow: -2px 1px 3px -1px;
  bottom: 0;

  .project-header {
    height: 50px;
    background-color: $primary-color;
    justify-content: space-between;

    h4 {
      line-height: 52px;
      font-size: 14px;
      overflow: hidden;
    }

    button {
      display: inline-block;
      height: 20px;
      margin-top: -2px;
    }
  }

  .chat-box {
    height: 260px;
    padding-left: 10px;
    overflow: scroll;
    overflow-x: hidden;
    background-image: url(../../images/chat-bg-2.png);
    background-repeat: no-repeat;
    background-size: 36%;
    background-position: center; //C:/clickinpedia/crm-fe-clickinpedia/public/assets/images/chat-bg-2.png);
    .message-box-area {
      width: fit-content;
      padding: 6px 5px 8px 8px;
      // border: 1px solid;
      background: rgb(10 55 106 / 82%);
      border-radius: 12px;
      margin-top: 9px;
      max-width: 278px;

      .sender-details {
        width: 101px;
        display: inline-block;
        // border: 1px solid;
        text-align: left;
        font-size: 11px;
      }
      .time {
        font-size: 8px;
        color: #a9a6aed4;
        height: 21px;
        display: inline-block;
        vertical-align: top;
        padding-left: 11px;
        padding-right: 10px;
      }
      .name {
        color: #ff4f00;
        font-weight: bold;
      }
      .user-type {
        font-size: 8px;
      }
    }
  }

  .chat-footer {
    justify-content: space-between;
    height: 51px;
    background-color: #0a376a;
    align-content: center;
    // span {
    //   margin-right: 16px;
    //   margin-top: 6px;
    // }
    .mic-icon {
      margin-left: 20px;
      margin-top: 4px;
    }
    .attach-file {
      margin: 3px 7px;
    }
    .send-emoji {
      margin: 3px 0px;
    }
    .inner-footer {
      width: 86%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
    .chat-input {
      // border: 1px solid;
      width: 68%;
      height: 100%;
      padding-left: 16px;
      background: transparent;
      border: 1px solid #fff;
      border-radius: 8px;
      padding: 6px 13px;
      color: #fff;
      padding-right: 38px;
    }

    .chat-input:focus {
      outline: none;
      // border: 0px;
    }

    .generate-payment-link {
      .icon-btn {
        span {
          margin: 0px;
          margin-right: 5px;
        }

        button {
          padding: 9px 11px;
        }
      }
    }

    .extra-field {
      width: 0px;
      height: 101px;
      background: white;
      position: absolute;
      bottom: 33px;
      right: 0px;
      transition: width 0.3s;
      overflow: hidden;
      padding-top: 10px;

      .send-file {
        line-height: 30px;
        padding-left: 9px;
        cursor: pointer;

        svg {
          display: inline-block;
        }

        span {
          font-size: 12px;
        }
      }
    }

    .extra-field.open {
      width: 111px;
    }
  }

  .sender {
    max-width: 100%;
    // background: #0a376ac2;
    color: white;
    width: fit-content;
    padding: 4px 14px;
    border-radius: 11px;
  }

  // .sender:hover .message::first-line {
  //   padding-right: 10px; /* Adjust padding as needed */
  // }

  .sender-name {
    // display: block;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: black;
    padding-left: 14px;
    // max-width: 232px;
    display: flex;
    justify-content: space-between;
  }

  .sender-me {
    text-align: -webkit-right;
  }

  .send-button {
    cursor: pointer;
    position: relative;
    margin-left: -35px;
    margin-top: 4px;
  }
}

.clear-both {
  clear: both;
}

.chat-body {
  transition: height 2s;
  // background-image: url(../../images/favicon.png);
  // background-size: cover;
}

.hide-chat {
  height: 0px;
}

@media only screen and (max-width: 1000px) {
  .custom-chat-area {
    width: 368px;
  }
}

@media only screen and (max-width: 386px) {
  .custom-chat-area {
    width: 348px;
  }
}

@media only screen and (max-width: 1000px) {
  .generate-payment-link {
    padding: 20px;

    .icon-btn {
      margin-top: 10px;
    }

    .input {
      margin-top: 10px;
    }
  }
}

.without-animation-icon {
  //   svg {
  font-size: 12px !important;
  margin: 0px 2px;
  padding: 0px;
  display: inline-block !important;
  animation: none !important;
  //   }
}

.maximum-chat-area {
  width: 92%;
  margin: 14px auto;
  .chat-box {
    height: 85vh;
    margin: 4px 8px;
    .sender-name {
      font-size: 13px;
    }
    .message-box-area {
      max-width: 650px;
    }
  }

  .chat-footer {
    .extra-field {
      height: 119px;
    }

    .send-file svg {
      height: 28px;
      width: auto;
    }

    .send-file span {
      font-size: 13px;
      padding-left: 7px;
    }

    .extra-field.open {
      width: 130px;
    }
  }
}

.custom-emoji {
  font-size: 32px;
}
.wave-recording {
  height: 32px;
  width: 80%;
}

.audio-message {
  width: 239px;
  height: 35px;
  display: inline-block;
}

.maximum-chat-area {
  .wave-recording {
    width: 76%;
  }
}

.ch-dialer-container {
  .ch-open-button {
    left: 14px !important;
  }
}

.action-dropdown-message {
  .MuiList-root {
    min-width: 225px;
    max-width: 225px;
    max-height: 700px;
    height: 300px;
  }
}

.masked-message-checkbox {
  height: 14px;
  width: 16px;
  margin-top: 2px;
  margin-left: 4px;
}

.custom-chat-area {
  .lead-details-chat {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.825);
    position: absolute;

    ul {
      list-style: none;
      li {
        display: inline-block;
        p {
          border: 1px solid white;
          margin: 2px;
          width: max-content;
          padding: 10px;
          border-radius: 12px;
          color: white;
          font-size: 10px;
          .value {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.maximum-chat-area {
  .lead-details-chat {
    p {
      font-size: 13px !important;
      margin: 10px !important;
    }
  }
}

.notice-message-chat {
  width: 100%;
  margin-top: -36px;
  background-color: yellow;
  p {
    text-align: center;
    color: red;
    font-size: 9.8px;
    padding-top: 8px;
  }
}

.maximum-chat-area {
  .notice-message-chat {
    p{
      font-size: 16px!important;
    }
  }
}
