//
// Extend from bootstrap
//

// Table



.table .thead-light th{
  color: $gray-600;
}




.table thead th {
  font-weight: 500;
  padding: 0.75rem 1.5rem;
  text-transform: capitalize;
  
  font-size: 0.875rem;
  color: $gray-600;

}
