.custom-quill-editor .ql-editor {
  min-height: 200px; /* Adjust this value as needed */
}

.custom-quill-editor .ql-container {
  height: 100%; /* Ensures the container fills the height */
}

.mark-query-chat-box {
  text-align: left;
  p {
    span,
    i,
    em,
    strong,
    code{
      color: white !important;
    }
  }
  h1,h2,h3{
    color: white !important;
  }
  ol {
    font-size: 12px;
    list-style: auto;
    margin-top: -5px;
  }
  ul{
    list-style: disc;
  }
  .file-list {
    i {
      font-size: 30px;
      margin: 2px 5px;
      color: white;
    }
  }
}


.react-datepicker-wrapper{
  width: 100%;
  input{
    border: 1px solid;
    border-radius: 4px;
    padding-left: 10px;
    width: 100%;
    }
}