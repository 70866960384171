.dashboard-skeleton {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .menu-icon, .notification-icon, .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .search-bar {
    width: 200px;
    height: 40px;
    border-radius: 20px;
  }
  
  .header-right {
    display: flex;
    gap: 10px;
  }
  
  .dashboard-title {
    width: 200px;
    height: 30px;
    margin-bottom: 20px;
  }
  
  .create-lead-btn {
    width: 150px;
    height: 40px;
    border-radius: 20px;
    float: right;
  }
  
  .metrics-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .metric-card {
    width: 22%;
    height: 120px;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .metric-icon {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .metric-value {
    width: 60%;
    height: 30px;
    margin-bottom: 5px;
  }
  
  .metric-label {
    width: 80%;
    height: 20px;
  }
  
  .active-leads-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .section-title {
    width: 150px;
    height: 25px;
    margin-bottom: 20px;
  }
  
  .leads-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .leads-table th, .leads-table td {
    padding: 15px 10px;
    text-align: left;
  }
  
  .lead-name {
    width: 80%;
    height: 20px;
  }
  
  .reg-date, .deadline {
    width: 60%;
    height: 20px;
  }
  
  .status-badge {
    width: 80px;
    height: 25px;
    border-radius: 15px;
  }
  
  .expert-avatars {
    display: flex;
    align-items: center;
  }
  
  .expert-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: -10px;
  }
  
  .expert-avatar-more {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #6c5ce7;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  
  .skeleton {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

  .dashboard-layout {
  display: flex;
  min-height: 100vh;
}

.sidebar-space {
  width: 250px; /* Adjust this value to change the sidebar width */
  background-color: #f8f9fa; /* Light gray background, change as needed */
  border-right: 1px solid #e0e0e0; /* Add a subtle border */
}

.dashboard-skeleton {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
}

/* Your existing dashboard skeleton styles... */

/* Adjust the header to not include the sidebar space */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
}

/* You might want to adjust other elements' widths or paddings */
.metrics-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 20px;
}

.active-leads-container {
  margin: 0 20px;
}

/* Add media query for responsiveness */
@media (max-width: 768px) {
  .dashboard-layout {
    flex-direction: column;
  }

  .sidebar-space {
    width: 100%;
    height: 60px; /* Adjust for mobile view */
  }
}