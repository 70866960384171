/* Global Variables */
:root {
  --bg-color: rgb(0, 0, 0);
  --primary-color: #0a376a;
  --secondary-color: #0f3a6b;
}

/* Utility Classes */
body {
  background-color: transparent;
}

.border-red {
  border: 1px solid red;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.width-37 {
  width: 37px !important;
}

.dark-bg .modal-content {
  background: hsl(228deg 29.41% 96.67%);
}

.relative {
  position: relative;
}

.mr-21 {
  margin-right: 24px !important;
}

.pointer {
  cursor: pointer;
}

.MuiButton-containedPrimary {
  background-color: var(--primary-color) !important;
}

.display-none {
  display: none;
}

.link {
  color: var(--primary-color) !important;
}

.bg-secondary-2 {
  background-color: var(--secondary-color);
}

.margin-right-0 {
  margin-right: 0px !important;
}

.margin-auto {
  margin: 0 auto;
}

/* Custom Chip Styles */
.custom-chip {
  padding-left: 0px;
}

.custom-chip .chip-child {
  padding: 4px 11px;
  font-weight: bold;
  font-size: 11px;
  border-radius: 14px;
}

.custom-chip .chip-child::before {
  content: "•";
  padding-right: 5px;
  font-size: 23px;
  line-height: 21px;
  vertical-align: sub;
}

/* Status Variations */
.custom-chip .ASSIGNED {
  background: rgba(23, 30, 109, 0.1);
  color: var(--primary-color);
}

.custom-chip .OPEN {
  background: rgba(11, 222, 22, 0.1);
  color: green;
}

.custom-chip .DONE {
  background: rgba(0, 32, 243, 0.1);
  color: blue;
}

.custom-chip .DELIVERED {
  background: rgba(3, 110, 7, 0.1);
  color: rgb(21, 208, 255);
}

.custom-chip .REOPEN {
  background: rgba(182, 106, 0, 0.125);
  color: rgb(255, 157, 21);
}

.custom-chip .REFUND {
  background: rgba(6, 225, 214, 0.125);
  color: rgb(21, 212, 255);
}

.custom-chip .ONHOLD {
  background: rgba(225, 134, 6, 0.125);
  color: rgb(255, 103, 21);
}

/* Number Input Styles */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Table Header Styles */
.rdt_TableHeader {
  display: none !important;
}

/* Toast Notification Styles */
.Toastify__toast-container {
  z-index: 100000 !important;
}

/* Mobile Preview */
.mobile-preview {
  width: 50%;
}

/* Custom Table Styles */
.custom-table {
  background-color: #002B5C;
  border-radius: 8px;
  overflow: hidden;
}

.custom-table th {
  background-color: #001B3D;
  color: #d1d5db;
  font-weight: 500;
  padding: 12px 16px;
  text-align: left;
}

.custom-table td {
  color: #f3f4f6;
  padding: 12px 16px;
  border-bottom: 1px solid #374151;
}

.custom-table tr:hover {
  background-color: #003366;
}

/* Status Badge Styles */
.status-badge {
  padding: 4px 8px;
  border-radius: 9999px;
  font-size: 12px;
  font-weight: 500;
}

.status-delivered {
  background-color: rgba(34, 197, 94, 0.2);
  color: #4ade80;
}

.status-onhold {
  background-color: rgba(249, 115, 22, 0.2);
  color: #fb923c;
}

.status-done {
  background-color: rgba(59, 130, 246, 0.2);
  color: #60a5fa;
}

.status-assigned {
  background-color: rgba(168, 85, 247, 0.2);
  color: #c084fc;
}


.max-width-modal-dialog{
  .modal-dialog{
  max-width: 3000px;
  }
}