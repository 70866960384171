.country-code-dropdown{
    width: 100%;
    .selected-value{
        position: absolute;
        margin-top: 9px;
        margin-left: 12px;
        cursor: pointer;
        z-index: 99999999999999999
    }
    .drop-down-list{
        height: 300px;
        width: 277px;
        background-color: #fdfdfd;
        position: absolute;
        margin-top: 40px;
        box-shadow: 1px 2px 2px;
        overflow: scroll;
        overflow-x: hidden;
        .search{
            border-bottom: 1px solid #e0dbdb;
            width: 100%;
            height: 38px;
            border-radius: 0px;
        }
        ul{
            padding: 4px 18px;
        }
        .list{
            margin-top: 10px;
            display: flex;
            cursor: pointer;
            img{
                width: 30px;
            }
            span{
                padding-left: 9px;
            }
        }
    }
}