// .dashboard-layout {
//   display: flex;
//   min-height: 100vh;
//   background-color: #f8f9fa;
// }

// .navbar-vertical {
//   width: 250px;
//   background-color: #ffffff;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//   transition: all 0.3s ease;
// }

// .dashboard-layout.toggled .navbar-vertical {
//   margin-left: -250px;
// }

// .dashboard-content {
//   flex: 1;
//   display: flex;
//   flex-direction: column;
// }

// .header-wrapper {
//   background-color: #ffffff;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   z-index: 1000;
// }

// .main-content {
//   flex: 1;
//   padding: 2rem;
//   overflow-y: auto;
// }

// .dashboard-footer {
//   background-color: #ffffff;
//   padding: 1rem 0;
//   border-top: 1px solid #e9ecef;
// }

// .footer-text {
//   font-size: 0.875rem;
//   color: #6c757d;
// }

// .footer-link {
//   color: #007bff;
//   text-decoration: none;
//   transition: color 0.2s ease;
// }

// .footer-link:hover {
//   color: #0056b3;
//   text-decoration: underline;
// }

.detail-card-lead{
    width: 800px;
    max-width: 800px;
}