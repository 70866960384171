// social color
.color-facebook {
    color: #4267B2;
}

.color-twitter {
    color: #1DA1F2;
}

.color-github {
    color: #000000;
}

.color-google {
    color: #db3236;
}

.color-slack {
    color: #ce375c;
}

.color-linkedin {
    color: #0077b5;
}