.custom-form{
  .form-control:focus{
    outline: 0px;
    border: 0;
    box-shadow: 1px 0px 4px 1px;
  }
}

.dashboard-search:focus{
    outline: 0px;
    border: 0;
    box-shadow: 1px 0px 4px 1px;
    width: 400px;
}

.full-screen-modal .modal-dialog{
  .modal-content {
    background: transparent;
    border: 0px;
}
  width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px auto;
}